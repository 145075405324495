import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const TermosMobile = ({data}) => {

  return(
    <StyledContainer>
      <h1 className={font.nexaRegular + " title"}>{data.title}</h1>
      <p className={font.nexaRegular + " subtitle"} style={{marginBottom:'2vh', fontSize:'2em'}}>{data.description}</p>
      {data.text.map((item, i) => (
        <div key={"text" + i} style={{marginBottom:'4vh'}}>
          <h3 className={font.nexaBold} style={{marginBottom:'1vh'}}>{item.title}</h3>
          <div className={font.nexaRegular + " text"} style={{lineHeight:'2vh'}} dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      ))}
    </StyledContainer>
  )
}

export default TermosMobile

const StyledContainer = styled.div`
  position: relative;
  width: 50%;
  padding-bottom:16vh;
  margin: 0 auto;
  z-index: 2;
  .title{
    color: #717171;
    text-transform: uppercase;
    letter-spacing: 0.38em;
  }
  .subtitle{
    color: #e6e6e6;
    font-size: calc(40px + 40 * (100vw - 769px) / 1791);
    @media only screen and (min-width: 2560px){font-size: 80px;}
    @media only screen and (max-width: 769px){font-size: 40px;}
  }
  .title, .subtitle, .text{
    margin: 2rem 0;
  }
  .text{
    width: 100%;
    color: #717171;
    white-space: pre-wrap;
    line-height: 1.5em;
  }
`