import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Apresentacao from "../components/apresentacao"
import ApresentacaoMobile from "../components/apresentacaoMobile"
import Intro from "../components/intro"
import Termos from "../components/termos"
import TermosMobile from "../components/termosMobile"
import Footer from "../components/footer"

import HeaderMobile from "../components/headerMobile"
import FooterMobile from "../components/footerMobile"

const TermosPage = ({ data }) => {

  console.log(data.informacoesJson);

  return (
    <Layout>
      <SEO title="Termos e Condições" />
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile header={data.globalJson.header} />
          <Intro data={data.termosJson.intro} blackBlue={true} mobile={true} />
          <ApresentacaoMobile data={data.termosJson.apresentacao} />
          <TermosMobile data={data.termosJson.terms} />
          <FooterMobile data={data.globalJson.footer} />
        </>
      ) : (
        <>
          <Header header={data.globalJson.header} />
          <Intro data={data.termosJson.intro} blackBlue={true} mobile={true} />
          <Apresentacao data={data.termosJson.apresentacao} />
          <Termos data={data.termosJson.terms} />
          <Footer data={data.globalJson.footer} />
        </>)
      }
    </Layout>
  )
}

export default TermosPage

export const Json = graphql`
  query termos {
    globalJson {
      header {
        menu {
          title
          link
        }
        menuselect {
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      footer {
        logo {
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
          txt
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social {
          face
          insta
          tube
        }
      }
    }
    termosJson {
      terms {
        title
        description
        text {
          title
          description
        }
      }
      apresentacao {
        subtitle
        text
        title
      }
      intro {
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
      }
      
    }
}
`
